<template>
  <div class="relative">
    <div class="vx-navbar-wrapper">
      <vs-navbar class="vx-navbar navbar-custom" :color="navbarColor" :class="classObj">

        <!-- BREADCRUMB -->
        <div vs-align="center" vs-w="5" class="navContainerOne">
          <h2>{{ routeTitle }}</h2>
        </div>
        <div class="navContainerTwo navNameContainer">
          <div>
            <h4>{{ nameAvatar }}</h4>
          </div>
        </div>
        <div class="navContainerThree navOrder">
          <vs-col class="navOrder">
            <div v-if="$acl.get[0]==='user'" class='navColumn'>
              <span class="font-weight-bold">Modo Análisis</span>
              <span class="font-weight-bold">
                <unicon name="wallet" fill="limegreen"></unicon>
              </span>
              <span class="font-weight-bold minPrice">Gratis</span>
            </div>
            <div v-else-if="$acl.get[0]==='sales'">
              <span class="font-weight-bold">Comercial</span>
            </div>
            <div v-else class='navColumn'>
              <span class="font-weight-bold">Modo Protección</span>
            </div>
          </vs-col>

          <!-- USER META -->
          <div class="the-navbar__user-meta flex items-center sm:ml-5 ml-2 navOrder">
            <div class="text-right leading-tight hidden sm:block">
              <p class="font-semibold">{{ activeUserInfo.name }}</p>
            </div>
            <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer nosquare">
              <div class="con-img ml-3">
                <avatar :username="nameAvatar" backgroundColor="rgb(47, 27, 122)" color="white" :size="40" class="rounded-full shadow-md cursor-pointer block"></avatar>
              </div>
              <vs-dropdown-menu>
                <ul style="min-width: 9rem">
                  <li v-if="$acl.get[0]!='sales'" id="Subscription_Menu" class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white liRoute">
                    <feather-icon icon="CreditCardIcon" svgClasses="w-4 h-4"></feather-icon>
                    <router-link to="/payment" class="ml-2">Suscripción</router-link>
                  </li>
                  <li v-if="$acl.get[0]!='sales'" id="Settings_Menu" class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white liRoute">
                    <feather-icon icon="SlidersIcon" svgClasses="w-4 h-4"></feather-icon>
                    <router-link to="/settings" class="ml-2">Ajustes</router-link>
                  </li>
                  <li id="LogOut_Menu" class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click.prevent="logout">
                    <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4"></feather-icon>
                    <span class="ml-2">Cerrar sesión</span>
                  </li>
                </ul>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>

      </vs-navbar>
    </div>
    <vs-sidebar parent="body" default-index="0" color="primary" class="sidebarx sidebarpage" spacer v-model="slideActive">
      <div class="input-search-cam-list">
        <b-form-input v-model="filter" placeholder="Buscar"></b-form-input>
      </div>
      <div class="table-cam-sel-cont scrollable-table">
        <b-table hover search :items="users" :fields="fieldsNavBar" :filter="filter" class="table-cam-sel" :tbody-tr-class="rowClass">
          <template v-slot:cell(name)="data">
            <span v-if="data.item.id!==$store.state.user.mainAccount" @click="selAccountClick(data.item)">{{data.item.name}}</span>
            <span v-else class="mainAccountRow">{{data.item.name}}</span>
          </template>
        </b-table>
      </div>
    </vs-sidebar>
    <b-alert v-model="showCreditAlert" class="position-fixed fixed-top m-0 rounded-0" style="z-index: 60000;" variant="danger" dismissible>{{alertCreditTxt}}</b-alert>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import themeConfig from '@/../themeConfig.js'
import { mapState } from 'vuex'
import { EventBus } from '@/event-bus'
import Amplify from 'aws-amplify'
import Avatar from 'vue-avatar'

export default {
  name: "the-navbar",
  props: {
    navbarColor: {
      type: String,
      default: "#fff",
    },
  },
  data() {
    return {
      currencyName: '',
      currencySymbol: '',
      nameAvatar: '',
      showCreditAlert: false,
      alertCreditTxt: '',
      filter: null,
      fieldsNavBar: [
        {
          key: 'name',
          label: 'Nombre'
        }
      ],
      users: [],
      slideActive: false,
      unreadNotifications: [
        { index: 0, title: 'Presupuesto', msg: 'El presupuesto está a punto de agotarse. Por favor recarga tu cuenta', icon: 'CreditCardIcon', time: 'Wed Jan 30 2019 07:45:23 GMT+0000 (GMT)', category: 'primary' }
      ],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
      autoFocusSearch: false,
      showBookmarkPagesDropdown: false,
      routeTitle: this.$route.meta.pageTitle,
      routerTransition: themeConfig.routerTransition || 'none',
      activeUserInfo: {
        trialDaysLeft: 0,
        trialPercentLeft: 0,
        trialColor: 'success',
        isTrialAccount: true,
        percentCredit: 0,
        credit: 0,
        creditColor: 'success'
      }
    }
  },
  watch: {
    '$route'() {
      this.routeTitle = this.$route.meta.pageTitle
    }
  },
  computed: {
    // HELPER
    sidebarWidth() {
      return this.$store.state.sidebarWidth
    },
    breakpoint() {
      return this.$store.state.breakpoint
    },
    // NAVBAR STYLE
    classObj() {
      if (this.sidebarWidth == "default") return "navbar-default"
      else if (this.sidebarWidth == "reduced") return "navbar-reduced"
      else if (this.sidebarWidth) return "navbar-full"
    },
    // PROFILE
    ...mapState([
      'user'
    ])
  },
  mounted() {
    EventBus.$on('user-get-credit', (credit) => {
      this.activeUserInfo.credit = Number(credit.toFixed(2))
    })
    EventBus.$on('use-admin', () => {
      this.users.length = 0
    })
    EventBus.$on('update-monitoring', (dataUser) => {
      this.users.length = 0
      for (let i = 0; i < dataUser.length; i++) {
        if (dataUser[i].monitoring) {
          this.users.push({ name: dataUser[i].name, id: dataUser[i].id })
        }
      }
    })
    EventBus.$on('update-monitoringAll', (dataUser) => {
      this.users.length = 0
      for (let i = 0; i < dataUser.length; i++) {
        if (dataUser[i].monitoring) {
          this.users.push({ name: dataUser[i].name, id: dataUser[i].id })
        }
      }
    })
    EventBus.$on('user-update-name', (dataName) => {
      this.nameAvatar = dataName
    })
    EventBus.$on('user-update-accounts', (dataUser) => {
      for (let i = 0; i < dataUser.accountsList.length; i++) {
        if (dataUser.accountsList[i].monitoring) {
          this.users.push({ name: dataUser.accountsList[i].name, id: dataUser.accountsList[i].id })
        }
      }
    })
    EventBus.$on('user-currencyName', (currencyName) => {
      this.currencyName = currencyName
    })
    EventBus.$on('user-currencySymbol', (currencySymbol) => {
      this.currencySymbol = currencySymbol
    })
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.name.startsWith('Checking_ieHn73mNfRS')) return 'hidden'
      if (item.id === this.$store.state.user.mainAccount) return 'table-selected'
    },
    openSlide() {
      this.slideActive = true
    },
    selAccountClick(account) {
      this.$store.dispatch('updateMainAccount', {
        id: account.id,
        name: account.name
      })
      this.slideActive = false
      EventBus.$emit('change-account-rules', account.id)
      EventBus.$emit('main-account-change', account.id)
      EventBus.$emit('update-side-account')
      EventBus.$emit('update-traffic')
      EventBus.$emit('update-blacklist')
    },
    elapsedTime(startTime) {
      let x = new Date(startTime)
      let now = new Date()
      var timeDiff = now - x
      timeDiff /= 1000
      var seconds = Math.round(timeDiff)
      timeDiff = Math.floor(timeDiff / 60)
      var minutes = Math.round(timeDiff % 60)
      timeDiff = Math.floor(timeDiff / 60)
      var hours = Math.round(timeDiff % 24)
      timeDiff = Math.floor(timeDiff / 24)
      var days = Math.round(timeDiff % 365)
      timeDiff = Math.floor(timeDiff / 365)
      var years = timeDiff
      if (years > 0) {
        return 'Hace' + years + (years > 1 ? ' Años ' : ' Año ')
      } else if (days > 0) {
        return 'Hace' + days + (days > 1 ? ' Días ' : ' Día ')
      } else if (hours > 0) {
        return 'Hace' + hours + (hours > 1 ? ' Horas ' : ' Hora ')
      } else if (minutes > 0) {
        return 'Hace' + minutes + (minutes > 1 ? ' Mins ' : ' Min ')
      } else if (seconds > 0) {
        return 'Hace' + seconds + (seconds > 1 ? `${seconds} segundos` : 'ahora mismo')
      }
      return 'Ahora mismo'
    },
    logout() {
      Amplify.Auth.signOut({ global: true })
        .then(() => this.$router.push({ path: '/auth' }), this.$store.dispatch("emptyUser", false), this.$acl.change('user'))
        .catch(() => this.$router.push({ path: '/auth' }), this.$store.dispatch("emptyUser", false), this.$acl.change('user'))
    },
  },
  components: {
    VuePerfectScrollbar,
    Avatar
  },
}
</script>
<style>
.noti-icon span.feather-icon-badge {
  top: -9px;
  right: -7px;
}
.account-list-item a {
  font-size: 1.1rem;
}
.campaign-sel-header {
  display: flex;
  color: #6e6e6e;
}
.campaign-sel-header h4 {
  color: #6e6e6e;
  cursor: pointer;
}
.icon-campaign {
  margin-top: -3px;
  margin-right: 5px;
}
.table-cam-sel thead {
  display: none;
}
.table-cam-sel-cont {
  padding: 1rem;
  font-size: 1.15rem;
}
.vs-navbar--btn-responsive {
  display: none;
}
@media (max-width: 830px) {
  .vs-navbar.vx-navbar {
    padding: 0.4rem 1rem;
  }
}
@media (max-width: 800px) {
  .navContainerTwo {
    width: 100% !important;
  }
  .vx-navbar > div:nth-child(2) {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .vx-navbar {
    width: calc(100vw - 15vh) !important;
  }
  .router-view .router-content {
    margin-top: 10.5rem;
  }
  .table-accounts .vs-table--header {
    flex-direction: column;
  }
  .flex-wrap-reverse {
    justify-content: center !important;
  }
  .mx-datepicker-range {
    width: 250px;
  }
}
</style>
<style scoped>
.input-search-cam-list {
  padding: 1rem;
}
.account-selected {
  margin-left: 6rem;
}
.route-title {
  margin-right: 1rem;
}
.move-width {
  width: 70% !important;
}
.scrollable-table {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
</style>
<style lang="scss">
.sidebarpage {
  .vs-sidebar {
    z-index: 60000;
    .vs-sidebar--items {
      overflow: auto;
    }
  }
}
.vx-navbar-wrapper {
  z-index: 19000;
}
.budget-bar,
.budget-bar div {
  border-radius: 0px !important;
}
.nosquare:focus,
.nosquare:active {
  outline: none !important;
}
.ontop {
  z-index: 90000;
  margin-left: 1%;
  font-size: 1.1em;
}
.ontopMoney {
  z-index: 90000;
}
.vs-con-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.navOrder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.navContainerOne {
  margin-left: 15px;
}
.navContainerTwo {
  width: 40%;
  justify-content: center;
}
.navContainerTwo > div {
  flex-direction: row;
  display: flex;
}
.navContainerThree {
  margin-right: 15px;
}
.navColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.liRoute > a {
  color: #212529;
  text-decoration: none;
}
.liRoute:hover > a {
  color: white;
}
.minPrice {
  color: #2f1b7a;
}
table.table tr.table-selected td {
  background-color: #2f1b7a;
  color: white;
}
.navNameContainer {
  display: flex;
  color: #6e6e6e;
}
.navNameContainer h4 {
  cursor: default;
}
</style>