var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _c(
        "div",
        { staticClass: "vx-navbar-wrapper" },
        [
          _c(
            "vs-navbar",
            {
              staticClass: "vx-navbar navbar-custom",
              class: _vm.classObj,
              attrs: { color: _vm.navbarColor }
            },
            [
              _c(
                "div",
                {
                  staticClass: "navContainerOne",
                  attrs: { "vs-align": "center", "vs-w": "5" }
                },
                [_c("h2", [_vm._v(_vm._s(_vm.routeTitle))])]
              ),
              _c("div", { staticClass: "navContainerTwo navNameContainer" }, [
                _c("div", [_c("h4", [_vm._v(_vm._s(_vm.nameAvatar))])])
              ]),
              _c(
                "div",
                { staticClass: "navContainerThree navOrder" },
                [
                  _c("vs-col", { staticClass: "navOrder" }, [
                    _vm.$acl.get[0] === "user"
                      ? _c("div", { staticClass: "navColumn" }, [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("Modo Análisis")
                          ]),
                          _c(
                            "span",
                            { staticClass: "font-weight-bold" },
                            [
                              _c("unicon", {
                                attrs: { name: "wallet", fill: "limegreen" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "font-weight-bold minPrice" },
                            [_vm._v("Gratis")]
                          )
                        ])
                      : _vm.$acl.get[0] === "sales"
                      ? _c("div", [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("Comercial")
                          ])
                        ])
                      : _c("div", { staticClass: "navColumn" }, [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("Modo Protección")
                          ])
                        ])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "the-navbar__user-meta flex items-center sm:ml-5 ml-2 navOrder"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-right leading-tight hidden sm:block"
                        },
                        [
                          _c("p", { staticClass: "font-semibold" }, [
                            _vm._v(_vm._s(_vm.activeUserInfo.name))
                          ])
                        ]
                      ),
                      _c(
                        "vs-dropdown",
                        {
                          staticClass: "cursor-pointer nosquare",
                          attrs: {
                            "vs-custom-content": "",
                            "vs-trigger-click": ""
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "con-img ml-3" },
                            [
                              _c("avatar", {
                                staticClass:
                                  "rounded-full shadow-md cursor-pointer block",
                                attrs: {
                                  username: _vm.nameAvatar,
                                  backgroundColor: "rgb(47, 27, 122)",
                                  color: "white",
                                  size: 40
                                }
                              })
                            ],
                            1
                          ),
                          _c("vs-dropdown-menu", [
                            _c("ul", { staticStyle: { "min-width": "9rem" } }, [
                              _vm.$acl.get[0] != "sales"
                                ? _c(
                                    "li",
                                    {
                                      staticClass:
                                        "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white liRoute",
                                      attrs: { id: "Subscription_Menu" }
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: {
                                          icon: "CreditCardIcon",
                                          svgClasses: "w-4 h-4"
                                        }
                                      }),
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "ml-2",
                                          attrs: { to: "/payment" }
                                        },
                                        [_vm._v("Suscripción")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.$acl.get[0] != "sales"
                                ? _c(
                                    "li",
                                    {
                                      staticClass:
                                        "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white liRoute",
                                      attrs: { id: "Settings_Menu" }
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: {
                                          icon: "SlidersIcon",
                                          svgClasses: "w-4 h-4"
                                        }
                                      }),
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "ml-2",
                                          attrs: { to: "/settings" }
                                        },
                                        [_vm._v("Ajustes")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "li",
                                {
                                  staticClass:
                                    "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                                  attrs: { id: "LogOut_Menu" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.logout($event)
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: {
                                      icon: "LogOutIcon",
                                      svgClasses: "w-4 h-4"
                                    }
                                  }),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v("Cerrar sesión")
                                  ])
                                ],
                                1
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "vs-sidebar",
        {
          staticClass: "sidebarx sidebarpage",
          attrs: {
            parent: "body",
            "default-index": "0",
            color: "primary",
            spacer: ""
          },
          model: {
            value: _vm.slideActive,
            callback: function($$v) {
              _vm.slideActive = $$v
            },
            expression: "slideActive"
          }
        },
        [
          _c(
            "div",
            { staticClass: "input-search-cam-list" },
            [
              _c("b-form-input", {
                attrs: { placeholder: "Buscar" },
                model: {
                  value: _vm.filter,
                  callback: function($$v) {
                    _vm.filter = $$v
                  },
                  expression: "filter"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table-cam-sel-cont scrollable-table" },
            [
              _c("b-table", {
                staticClass: "table-cam-sel",
                attrs: {
                  hover: "",
                  search: "",
                  items: _vm.users,
                  fields: _vm.fieldsNavBar,
                  filter: _vm.filter,
                  "tbody-tr-class": _vm.rowClass
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(name)",
                    fn: function(data) {
                      return [
                        data.item.id !== _vm.$store.state.user.mainAccount
                          ? _c(
                              "span",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.selAccountClick(data.item)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(data.item.name))]
                            )
                          : _c("span", { staticClass: "mainAccountRow" }, [
                              _vm._v(_vm._s(data.item.name))
                            ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]
      ),
      _c(
        "b-alert",
        {
          staticClass: "position-fixed fixed-top m-0 rounded-0",
          staticStyle: { "z-index": "60000" },
          attrs: { variant: "danger", dismissible: "" },
          model: {
            value: _vm.showCreditAlert,
            callback: function($$v) {
              _vm.showCreditAlert = $$v
            },
            expression: "showCreditAlert"
          }
        },
        [_vm._v(_vm._s(_vm.alertCreditTxt))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }